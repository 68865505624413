import React from "react"
import TextSection from "../TextSection"

const SchlesingerInfo = () => (
  <TextSection headingText={'INFORMATION'}>
    <div className="mb-4">“The work of the young is to be alive to the present, and the present after that. That’s the work of the artist, too– to immerse himself in the moment. Peter’s pictures document not only his openness to the current events and fleeting wisdom of the past, but to what he saw and what he made of it.” </div>
    <div className="mb-4">
      <span className="font-glaston"> — Hilton Als</span>, foreword to A Photographic Memory
    </div>
    <div className="mb-4">Paris based Mariposa Gallery is pleased to announce their inaugural exhibition featuring photographs by the artist Peter Schlesinger. The presentation will include images taken by Schlesinger on trips to France from 1969 - 1979. Schlesinger, a noted ceramicist and painter is also known for being the subject in numerous notable canvases by the British painter David Hockney. This will be the first exhibition of Schlesinger’s photography in Europe.</div>
    <div className="mb-4">Schlesinger’s photographic journey began in 1966 when as an 18-year-old student he met the artist David Hockney, then a visiting professor at UCLA. After a short period living together in Los Angeles, the two then moved to London where Schlesinger was introduced to the city’s fashionable bohemian society. There, as well as on trips, Schlesinger met and photographed some of the great personalities of the day, including Hockney, Cecil Beaton, Christopher Isherwood, Amanda Lear, and Andy Warhol. His photography is the subject of two volumes A Checkered Past (2003), and Peter Schlesinger: A Photographic Memory 1968–1989 (2015).</div>
    <div className="mb-4">Schlesinger approached photography pragmatically– he used amateur print film and processed the images at his corner pharmacy. With this informal strategy, akin to Jacques-Henri Lartigue, whom the artist met and photographed in 1971, Schlesinger captured larger-than-life figures with intimacy and immediacy, their humanity frozen in time with the click of his lens. The images convey the clear emotional state of their maker: hopeful, romantic, imaginative, free.</div>
    <div className="mb-4">This exhibition brings together a group of Schlesinger’s photographs taken on trips to France between 1969-1979, a country in the midst of a rich cultural moment that attracted the leading figures of art, fashion, and society. Through Schlesinger’s eyes, we see Grace Coddington with Schlesinger’s late partner Eric Boman on the Rue de Rivoli, the year she started her legendary career at American Vogue (1972), Robert Mapplethorpe slipping out of a camera shop on the Boulevard Saint-Germain (1971), Tina Chow and Paloma Picasso reapplying their makeup during Paloma’s wedding at Karl Lagerfeld’s apartment (1978), and Andy Warhol reading the newspaper in the back of a taxi in Monaco (1974). A striking overhead photograph of bathers at the infamous barge La Piscine Deligny in Paris (1975) and a snap of ripe summer fruit at a snack shop in St. Tropez (1974) show Schlesinger’s strong eye for composition within his snapshot aesthetic.  The selection curated by dealer Alec Smyth brings Schlesinger’s images from France together for the first time, representing a particularly impactful and productive period for the artist.</div>
    <div className="mb-4">Peter Schlesinger was born and raised in California. His sculptures and paintings have been exhibited widely and are in the collections of the Parrish Art Museum, The Farnsworth Museum, and Manchester Gallery of Art, England.   He currently resides in New York City and in Bellport Long Island. Peter Schlesinger is represented by David Lewis Gallery.</div>
  </TextSection>
)

export default SchlesingerInfo